.dashboard-title {
  margin-bottom: 10px; /* Reduce bottom margin to minimize space */
  text-align: center;
}

.dashboard-container {
  padding: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px; /* Adjust spacing between buttons and previous elements */
}

.logout-button {
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #333;
}
